import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FaUser, FaUsersCog } from 'react-icons/fa'

import { businessTypes, shareholderType } from 'dataSource/constants'
import { useBusiness } from 'backend/useBusiness'
import { selectBusiness } from 'features/businessSlice'
import { selectUi, uiActions } from 'features/uiSlice'
import { isEmpty } from 'utils/helpers'
import Placeholder from 'Components/Placeholder'

import ShareholderIndividual from './ShareholderIndividual'
import ShareholderCorporate from './ShareholderCorporate'
import ShareholderFamily from './ShareholderFamily'

import ShareholderModalSelect from './ShareholderModalSelect'
import ShareholderModalAdd from './ShareholderModalAdd'
import ShareholderModalEdit from './ShareholderModalEdit'
import ShareholderModalRemove from './ShareholderModalRemove'
import ShareholderLegacy from './ShareholderLegacy'

const ShareholderDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { reUsableModal } = useSelector(selectUi)
  const [selectedBusiness, setSelectedBusiness] = useState({})
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { business } = useSelector(selectBusiness)

  const { getBusiness } = useBusiness()

  useEffect(() => {
    getBusiness(id).then((item) => {
      let shareholderType = item?.shareholderType
      let shareholders = item?.shareholders === null ? [] : item?.shareholders
      let corporateShareholders =
        item?.corporateShareholders === null ? [] : item?.corporateShareholders
      if (
        item?.businessType === businessTypes.family &&
        item?.shareholderType === null
      ) {
        shareholderType = shareholderType.Individual
      }
      // shareholderType = null

      setSelectedBusiness({
        ...item,
        shareholderType,
        shareholders,
        corporateShareholders,
        // blocked: true,
        // expired: true,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, business])

  const selectType = () => {
    dispatch(uiActions.openReUsableModal({ key: 'select' }))
  }

  const add = (i) => {
    setSelectedIndex(i)
    dispatch(uiActions.openReUsableModal({ key: 'add' }))
  }

  const edit = (i) => {
    setSelectedIndex(i)
    dispatch(uiActions.openReUsableModal({ key: 'edit' }))
  }

  const remove = (i) => {
    setSelectedIndex(i)
    dispatch(uiActions.openReUsableModal({ key: 'remove' }))
  }

  console.log('selectedBusiness', selectedBusiness)

  return (
    <div>
      {reUsableModal.key === 'select' && (
        <ShareholderModalSelect
          business={selectedBusiness}
          id={selectedBusiness?.id}
        />
      )}
      {reUsableModal.key === 'add' && (
        <ShareholderModalAdd
          business={selectedBusiness}
          id={selectedBusiness?.id}
          index={selectedIndex}
        />
      )}
      {reUsableModal.key === 'edit' && (
        <ShareholderModalEdit
          business={selectedBusiness}
          id={selectedBusiness?.id}
          index={selectedIndex}
        />
      )}
      {reUsableModal.key === 'remove' && (
        <ShareholderModalRemove
          business={selectedBusiness}
          id={selectedBusiness?.id}
          index={selectedIndex}
        />
      )}
      {isEmpty(selectedBusiness) ? (
        <Placeholder icon={FaUser} text="Your Shareholders will display here" />
      ) : selectedBusiness?.businessType === businessTypes.family ? (
        <ShareholderFamily
          shareholders={selectedBusiness?.shareholders}
          shareholderCount={selectedBusiness?.shareholderCount}
          edit={edit}
          add={add}
          remove={remove}
          changeType={<ChangeType selectType={selectType} />}
        />
      ) : selectedBusiness?.businessType === businessTypes.legacy ? (
        <ShareholderLegacy
          shareholders={selectedBusiness?.shareholders}
          shareholderCount={selectedBusiness?.shareholderCount}
          edit={edit}
          add={add}
          remove={remove}
          changeType={
            <ChangeType selectType={selectType} title="Change Trustee Type" />
          }
        />
      ) : selectedBusiness?.shareholderType === shareholderType.Corporate ? (
        <ShareholderCorporate
          corporateShareholders={selectedBusiness?.corporateShareholders}
          edit={edit}
          add={add}
          remove={remove}
          changeType={<ChangeType selectType={selectType} />}
        />
      ) : selectedBusiness?.shareholderType === shareholderType.Individual ? (
        <ShareholderIndividual
          shareholders={selectedBusiness?.shareholders}
          shareholderCount={selectedBusiness?.shareholderCount}
          edit={edit}
          add={add}
          remove={remove}
          changeType={<ChangeType selectType={selectType} />}
        />
      ) : (
        <SelectType selectType={selectType} />
      )}
      {/* <pre>{JSON.stringify(selectedBusiness, null, 2)}</pre> */}
    </div>
  )
}

export default ShareholderDetails

const ChangeType = ({
  selectType = () => {},
  title = 'Change Shareholder Type',
}) => {
  return Array(1)
    .fill(1)
    .map((n, i) => (
      <div
        className="col-auto bg-black text-sm text-muted p-4 rounded-lg"
        key={i}
      >
        <div className="flex justify-center items-center  h-full p-10">
          <button
            className="m-0 p-0 text-sm text-gold-400"
            onClick={() => selectType()}
          >
            <FaUsersCog className="text-3xl inline-block mb-1" />
            <div>{title}</div>
          </button>
        </div>
      </div>
    ))
}

const SelectType = ({ selectType = () => {} }) => {
  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Shareholder Details</h2>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <div className="bg-black text-sm text-muted p-4 rounded-lg">
          <div className="flex justify-center items-center p-10">
            <button
              className="m-0 p-0 text-sm text-gold-400"
              onClick={() => selectType()}
            >
              <FaUsersCog className="text-3xl inline-block mb-1" />
              <div>Select Shareholder Type</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
