import React from 'react'
import { format } from 'date-fns'
import { countryNameByIso2 } from 'dataSource/countries'
import { FaEdit, FaUserPlus, FaTrash } from 'react-icons/fa'

const BeneficiaryCorporate = ({
  corporateBeneficiaries = [],
  changeType,
  add = () => {},
  edit = () => {},
  remove = () => {},
}) => {
  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Corporate Beneficiary Details</h2>
      </div>
      <div className="grid grid-cols-2 gap-5">
        {(corporateBeneficiaries || []).map((item, i) => (
          <div
            className="col-auto relative block text-xs bg-black rounded-lg p-4"
            key={i}
          >
            <div className="absolute right-3 top-3 flex gap-2">
              <FaEdit
                className="text-gold-500 text-lg cursor-pointer"
                onClick={() => edit(i)}
              />
              <FaTrash
                className="text-gold-500 text-lg cursor-pointer"
                onClick={() => remove(i)}
              />
            </div>
            <div className="mb-2">
              <span className="text-muted">Corporate/Business Name: </span>
              <b>{item?.businessName}</b>
            </div>
            <div className="mb-2">
              <span className="text-muted">Registration/Filing Number: </span>
              <b>{item?.filingNumber}</b>
            </div>
            <div className="mb-2">
              <span className="text-muted">
                Corporate Date of Registration:{' '}
              </span>
              <b>
                {item?.regDate && format(new Date(item?.regDate), 'dd/MM/yyyy')}
              </b>
            </div>
            {/* <div className="mb-2">
              <span className="text-muted">Street: </span>
              <b>{shareholder?.street}</b>
              </div> */}
            <div className="mb-2">
              <span className="text-muted">City: </span>
              <b>{item?.city}</b>
            </div>
            <div className="mb-2">
              <span className="text-muted">State: </span>
              <b>{item?.state}</b>
            </div>
            <div className="mb-2">
              <span className="text-muted">
                Country of Business Residency:{' '}
              </span>
              <b>{countryNameByIso2(item?.country)}</b>
            </div>
            <div className="mb-2">
              <span className="text-muted">Zip Code: </span>
              <b>{item?.zipCode}</b>
            </div>
            {/* <div className="mb-2">
            <span className="text-muted">Mobile Number: </span>
            <b>{shareholder?.mobile}</b>
          </div>
          <div className="mb-2">
            <span className="text-muted">Email: </span>
            <b>{shareholder?.email}</b>
          </div> */}
            <div>
              <span className="text-muted">Share: </span>
              <b>{item?.share}%</b>
            </div>
          </div>
        ))}
        {Array(1 - corporateBeneficiaries?.length)
          .fill(1)
          .map((n, i) => (
            <div
              className="col-auto bg-black text-sm text-muted p-4 rounded-lg"
              key={i}
            >
              <div className="flex justify-center items-center  h-full p-10">
                <button
                  className="m-0 p-0 text-sm text-gold-400"
                  onClick={() => add(i)}
                >
                  <FaUserPlus className="text-3xl inline-block mb-1" />
                  <div>Add Corporate Beneficiary</div>
                </button>
              </div>
            </div>
          ))}
        {changeType}
      </div>
    </div>
  )
}

export default BeneficiaryCorporate
