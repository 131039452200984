import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Placeholder from 'Components/Placeholder'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { useAsset } from 'backend/useAsset'

const AssetDetails = () => {
  const { id } = useParams()
  const [formData, setFormData] = useState({})

  const { getSpecificAsset } = useAsset()

  useEffect(() => {
    getSpecificAsset(id).then((item) => setFormData(item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Asset Details</h2>
      </div>
      <div>
        <div className="mb-4 rounded-md w-full">
          <div className="font-semibold">
            <div className="">
              <div className="bg-black text-sm text-muted p-4 rounded-lg">
                <div className="flex justify-between mb-4">
                  <div className="text-lg">{formData?.businessFullName}</div>
                </div>
                <div className="">
                  <div>
                    <div className="mb-2">
                      <span>Name: </span>
                      <b className="text-white text-xs inline">
                        {formData?.name}
                      </b>
                    </div>
                    <div className="mb-2">
                      <span className="text-muted">Description: </span>
                      <b className="text-white text-xs inline">
                        {formData?.description}
                      </b>
                    </div>
                    <div className="mb-2">
                      <span className="text-muted">Category: </span>
                      <b className="text-white text-xs inline">
                        {formData?.category}
                      </b>
                    </div>
                    <div className="mb-2">
                      <span className="text-muted">Sub Category: </span>
                      <b className="text-white text-xs inline">
                        {formData?.subCategory}
                      </b>
                    </div>
                    <div className="mb-2">
                      <span className="text-muted">quantity: </span>
                      <b className="text-white text-xs inline">
                        {formData?.quantity}
                      </b>
                    </div>
                    <div className="mb-2">
                      <span className="text-muted">Price in Matic: </span>
                      <b className="text-white text-xs inline">
                        {formData?.priceInMatic}
                      </b>
                    </div>
                    <div className="mb-2">
                      <span className="text-muted">Price: </span>
                      <b className="text-white text-xs inline">
                        {formData?.price}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  {formData?.documents?.map((document) => (
                    <div key={document.id} className="mb-4 mr-6">
                      <div>
                        <p className="mt-6 pb-2 mr-4">{document.name}</p>
                        {document.s3Url ? (
                          <object
                            data={document.s3Url}
                            width="300"
                            height="400"
                            aria-label="Embedded Document"
                          ></object>
                        ) : (
                          <Placeholder
                            icon={FaFileInvoiceDollar}
                            text={!document?.s3Url && 'No asset found'}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssetDetails
