import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FaEdit, FaPlusCircle } from 'react-icons/fa'
import { countryNameByIso2 } from 'dataSource/countries'
import { useBusiness } from 'backend/useBusiness'
// import AddressModal from "./AddressModal"
import AddressCommunicationModal from './AddressCommunicationModal'
import AddressBillingModal from './AddressBillingModal'

const AddressPreference = () => {
  const [address, setAddress] = useState('')
  const { id } = useParams()
  const [formData, setFormData] = useState({})

  const { getBusiness, getBusinessWithAddress } = useBusiness()

  useEffect(() => {
    getBusiness(id).then((item) => setFormData(item))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const editAddress = (address) => {
    setAddress(address)
  }

  const closeModal = () => {
    setAddress('')
    getBusinessWithAddress(id).then((item) => setFormData(item))
  }

  console.log(formData)

  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Address Preferences</h2>
      </div>
      <div className="">
        <div className=" my-4 rounded-md w-full font-semibold">
          <div className="grid grid-cols-2 gap-5">
            <div className="bg-black text-sm text-muted p-4 rounded-lg">
              <div className="flex justify-between mb-4">
                <div className="text-lg">Communications Contact</div>
                {formData?.communicationAddress?.addressType && (
                  <button
                    className="m-0 p-0 text-sm text-gold-400 flex items-center"
                    onClick={() => editAddress('Communications')}
                  >
                    <FaEdit className="mr-1 inline-block" /> Edit
                  </button>
                )}
              </div>
              {formData?.communicationAddress?.addressType ? (
                <>
                  <div className="mb-2">
                    <div className="mr-1">
                      {formData?.communicationAddress?.prefix && (
                        <span className="pr-1">
                          {formData?.communicationAddress?.prefix}
                        </span>
                      )}
                      {formData?.communicationAddress?.firstName && (
                        <span className="pr-1">
                          {formData?.communicationAddress?.firstName}
                        </span>
                      )}
                      {formData?.communicationAddress?.lastName && (
                        <span className="pr-1">
                          {formData?.communicationAddress?.lastName}
                        </span>
                      )}
                      {formData?.communicationAddress?.suffix && (
                        <span className="pr-1">
                          {formData?.communicationAddress?.suffix}
                        </span>
                      )}
                    </div>
                    <div className="mr-1">
                      {formData?.communicationAddress?.addressLine1}
                    </div>
                    <div className="mr-1">
                      {formData?.communicationAddress?.addressLine2}
                    </div>
                    <div className="mr-1">
                      {formData?.communicationAddress?.city}
                    </div>
                    <span className="mr-1">
                      {formData?.communicationAddress?.state}
                    </span>
                    <span className="mr-1">
                      {formData?.communicationAddress?.zipCode}
                    </span>
                    <div className="mr-1">
                      {countryNameByIso2(
                        formData?.communicationAddress?.country
                      )}
                    </div>
                  </div>
                  {formData?.communicationAddress?.email && (
                    <div className="my-3">
                      {/* <span className="text-muted">Phone Number: </span> */}
                      <b>{formData?.communicationAddress?.phone}</b>
                    </div>
                  )}
                  {formData?.communicationAddress?.email && (
                    <div className="my-3">
                      {/* <span className="text-muted">Email: </span> */}
                      <b>{formData?.communicationAddress?.email}</b>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center p-10">
                  <button
                    className="m-0 p-0 text-sm text-gold-400"
                    onClick={() => editAddress('Communications')}
                  >
                    <FaPlusCircle className="text-3xl inline-block mb-1" />
                    <div>Add Address</div>
                  </button>
                </div>
              )}
            </div>

            <div className="bg-black text-sm text-muted p-4 rounded-lg">
              <div className="flex justify-between mb-4">
                <div className="text-lg">Annual Billing Information</div>
                {formData?.billingAddress?.addressType && (
                  <button
                    className="m-0 p-0 text-sm text-gold-400 flex items-center"
                    onClick={() => editAddress('Billing')}
                  >
                    <FaEdit className="mr-1 inline-block" /> Edit
                  </button>
                )}
              </div>
              {formData?.billingAddress?.addressType ? (
                <>
                  <div className="mb-2">
                    <div className="mr-1">
                      {formData?.billingAddress?.prefix && (
                        <span className="pr-1">
                          {formData?.billingAddress?.prefix}
                        </span>
                      )}
                      {formData?.billingAddress?.firstName && (
                        <span className="pr-1">
                          {formData?.billingAddress?.firstName}
                        </span>
                      )}
                      {formData?.billingAddress?.lastName && (
                        <span className="pr-1">
                          {formData?.billingAddress?.lastName}
                        </span>
                      )}
                      {formData?.billingAddress?.suffix && (
                        <span className="pr-1">
                          {formData?.billingAddress?.suffix}
                        </span>
                      )}
                    </div>
                    <div className="mr-1">
                      {formData?.billingAddress?.addressLine1}
                    </div>
                    <div className="mr-1">
                      {formData?.billingAddress?.addressLine2}
                    </div>
                    <div className="mr-1">{formData?.billingAddress?.city}</div>
                    <span className="mr-1">
                      {formData?.billingAddress?.state}
                    </span>
                    <span className="mr-1">
                      {formData?.billingAddress?.zipCode}
                    </span>
                    <div className="mr-1">
                      {countryNameByIso2(formData?.billingAddress?.country)}
                    </div>
                  </div>
                  {formData?.billingAddress?.email && (
                    <div className="my-3">
                      <b>{formData?.billingAddress?.phone}</b>
                    </div>
                  )}
                  {formData?.billingAddress?.email && (
                    <div className="my-3">
                      <b>{formData?.billingAddress?.email}</b>
                    </div>
                  )}
                  {formData?.billingAddress?.email2 && (
                    <div className="my-3">
                      <b>{formData?.billingAddress?.email2}</b>
                    </div>
                  )}
                  {/* {formData?.billingAddress?.email3 &&
                    <div className="my-3">
                      <b>{formData?.billingAddress?.email3}</b>
                    </div>
                  } */}
                  {formData?.billingAddress?.noticeFrequency && (
                    <div className="my-3">
                      <div className="font-semibold text-lg">
                        Registered Agent Notification Frequency:
                      </div>
                      <b>{formData?.billingAddress?.noticeFrequency}</b>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center p-10">
                  <button
                    className="m-0 p-0 text-sm text-gold-400"
                    onClick={() => editAddress('Billing')}
                  >
                    <FaPlusCircle className="text-3xl inline-block mb-1" />
                    <div>Add Address</div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <AddressModal isOpen={open} closeModal={closeModal} address={address} business={formData} /> */}
      <AddressCommunicationModal
        isOpen={address === 'Communications'}
        closeModal={closeModal}
        addressType="Communications"
        address={formData?.communicationAddress}
        ids={[{ id: formData?.id }]}
      />
      <AddressBillingModal
        isOpen={address === 'Billing'}
        closeModal={closeModal}
        addressType="Billing"
        address={formData?.billingAddress}
        ids={[{ id: formData?.id }]}
      />
    </div>
  )
}

export default AddressPreference
