import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  FaHeadset,
  FaDollarSign,
  // FaAddressBook,
  FaFileInvoiceDollar,
  FaBuilding,
  FaUser,
  FaSignOutAlt,
  // FaUserFriends,
} from 'react-icons/fa'
import { HiMenu, HiMenuAlt3 } from 'react-icons/hi'
import { GiGoldBar } from 'react-icons/gi'
import logo from 'assets/logo-banner.jpg'
import { useClickAway, useWindowSize } from 'react-use'
import cx from 'classnames'

const Nav = () => {
  const navRef = useRef(null)
  const [dropdown, setDropdown] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const { width } = useWindowSize()
  const location = useLocation()

  useEffect(() => {
    if (width > 768 && isOpen) {
      setIsOpen(false)
    }
  }, [width, isOpen])

  useClickAway(navRef, () => {
    setDropdown(null)
  })

  useEffect(() => {
    setDropdown(null)
    if (!isOpen) {
      setIsOpen(false)
    }
  }, [isOpen])

  useEffect(() => {
    setDropdown(null)
  }, [location])

  return (
    <div
      className={cx('md:w-3/4 mx-auto flex items-center justify-between px-8', {
        'bg-black absolute top-0 left-0 right-0': isOpen,
      })}
    >
      <div className="w-20 md:w-36 md:h-24 my-4 md:my-8">
        <Link to="/">
          <img src={logo} className="inline-block" alt="logo" />
        </Link>
      </div>
      <div className="md:hidden cursor-pointer">
        {React.createElement(isOpen ? HiMenuAlt3 : HiMenu, {
          className: 'text-3xl text-gold-500',
          onClick: () => setIsOpen(!isOpen),
        })}
      </div>
      <nav
        ref={navRef}
        className={cx(
          'absolute duration-150 md:relative md:block top-20 md:top-auto bg-black left-0 right-0',
          { hidden: !isOpen, 'p-8 z-50': isOpen }
        )}
      >
        <ul
          className="grid md:grid-flow-col gap-6 md:gap-8 text-center text-muted"
          onClick={() => setIsOpen(false)}
        >
          <li className="text-center">
            <Link to="/contact" className="block">
              <FaHeadset className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" />
              <span>Contact Us</span>
            </Link>
          </li>
          <li className="text-center">
            <Link to="/orders" className="block">
              <FaDollarSign className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" />
              <span>Payment</span>
            </Link>
          </li>
          <li className="text-center">
            <Link to="/companies" className="block">
              <FaBuilding className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" />
              <span>Companies</span>
            </Link>
          </li>
          <li className="text-center">
            <Link to="/upcoming" className="block">
              <FaFileInvoiceDollar className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" />
              <span>Upcoming Bills</span>
            </Link>
          </li>
          <li className="text-center">
            <Link to="/asset" className="block">
              <GiGoldBar className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" />
              <span>RWA</span>
            </Link>
          </li>
          {/* <li className="text-center">
            <Link to="/address" className="block">
              <FaAddressBook className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" />
              <span>Address Book</span>
            </Link>
          </li>
          <li className="text-center">
            <Link to="/shareholders" className="block">
              <FaUserFriends className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" />
              <span>Shareholders</span>
            </Link>
          </li> */}
          {!isOpen ? (
            <li
              className="text-center dropdown"
              onClick={() => {
                setDropdown('company')
              }}
              onMouseEnter={() => {
                dropdown !== 'company' && setDropdown('company')
              }}
              // onMouseOut={() => {
              //   dropdown === true && setDropdown(false)
              // }}
            >
              <Link to="#" className="block">
                <FaBuilding className="mx-auto mb-1 text-2xl md:text-3xl text-gold-500" />
                <span>Form a Company</span>
              </Link>
              {dropdown === 'company' && (
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/register/trust">Blockchain Trust</Link>
                  </li>
                  <li>
                    <Link to="/register/corporate">Blockchain Corporation</Link>
                  </li>
                  <li>
                    <Link to="/register/family">Blockchain Family Trust</Link>
                  </li>
                  <li>
                    <Link to="/register/legacy">Blockchain Legacy Trust</Link>
                  </li>
                  <li>
                    <Link to="/register/bank">Blockchain Bank</Link>
                  </li>
                  <li>
                    <Link to="/register/insurance">Captive Insurance</Link>
                  </li>
                </ul>
              )}
            </li>
          ) : (
            <>
              <li className="text-center">
                <Link to="/register/trust" className="block">
                  {/* <FaBuilding className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" /> */}
                  <span>Form a Blockchain Trust</span>
                </Link>
              </li>
              <li className="text-center">
                <Link to="/register/corporate" className="block">
                  {/* <FaBuilding className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" /> */}
                  <span>Form a Blockchain Corporation</span>
                </Link>
              </li>
              <li className="text-center">
                <Link to="/register/family" className="block">
                  {/* <FaBuilding className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" /> */}
                  <span>Form a Blockchain Family Trust</span>
                </Link>
              </li>
              <li className="text-center">
                <Link to="/register/legacy" className="block">
                  {/* <FaBuilding className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" /> */}
                  <span>Form a Blockchain Legacy Trust</span>
                </Link>
              </li>
              <li className="text-center">
                <Link to="/register/bank" className="block">
                  {/* <FaBuilding className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" /> */}
                  <span>Form a Blockchain Bank</span>
                </Link>
              </li>
              <li className="text-center">
                <Link to="/register/insurance" className="block">
                  {/* <FaBuilding className="hidden md:block mx-auto mb-1 text-2xl md:text-3xl text-gold-500" /> */}
                  <span>Captive Insurance</span>
                </Link>
              </li>
            </>
          )}
          {!isOpen ? (
            <li
              className="text-center dropdown"
              onClick={() => {
                setDropdown('account')
              }}
              onMouseEnter={() => {
                dropdown !== 'account' && setDropdown('account')
              }}
              // onMouseOut={() => {
              //   accountDropdown === true && setAccountDropdown(false)
              // }}
            >
              <Link to="/account" className="block">
                <FaUser className="mx-auto mb-1 text-2xl md:text-3xl text-gold-500" />
                <span>Account</span>
              </Link>
              {dropdown === 'account' && (
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/address">Address Book</Link>
                  </li>
                  <li>
                    <Link to="/shareholders">Shareholders</Link>
                  </li>
                  <li>
                    <Link to="/beneficiaries">Beneficiaries</Link>
                  </li>
                  <li>
                    <Link to="/auth/logout">
                      Log Out <FaSignOutAlt className="inline ml-1" />
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          ) : (
            <>
              <li>
                <Link to="/address">Address Book</Link>
              </li>
              <li>
                <Link to="/shareholders">Shareholders</Link>
              </li>
              <li>
                <Link to="/account">Account</Link>
              </li>
              <li>
                <Link to="/auth/logout">Log out</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  )
}

export default Nav
