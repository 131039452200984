import React from 'react'
import { format } from 'date-fns'

const ShareholderDetailsByType = ({ shareholder }) => {
  switch (shareholder.type) {
    case 'Individual':
      return (
        <div>
          {shareholder?.firstName} {shareholder?.lastName}
          {shareholder?.dob && (
            <div>
              Dob{' '}
              <span className="text-gold-400">
                {format(new Date(shareholder?.dob), 'dd/MM/yyyy')}
              </span>
            </div>
          )}
          {shareholder?.passportNumber && (
            <div>
              Passport/ID Number{' '}
              <span className="text-gold-400">
                {shareholder?.passportNumber}
              </span>
            </div>
          )}
        </div>
      )
    case 'Family':
      return (
        <div>
          {shareholder?.firstName} {shareholder?.lastName}
          {shareholder?.dob && (
            <div>
              Dob{' '}
              <span className="text-gold-400">
                {format(new Date(shareholder?.dob), 'dd/MM/yyyy')}
              </span>
            </div>
          )}
          {shareholder?.passportNumber && (
            <div>
              Passport/ID Number{' '}
              <span className="text-gold-400">
                {shareholder?.passportNumber}
              </span>
            </div>
          )}
        </div>
      )
    case 'Corporate':
      return (
        <div>
          {shareholder?.businessName}
          {shareholder?.regDate && (
            <div>
              Registered Date{' '}
              <span className="text-gold-400">
                {format(new Date(shareholder?.regDate), 'dd/MM/yyyy')}
              </span>
            </div>
          )}
          {shareholder?.filingNumber && (
            <div>
              Filing Number{' '}
              <span className="text-gold-400">{shareholder?.filingNumber}</span>
            </div>
          )}
        </div>
      )
    default:
      return () => null
  }
}

export default ShareholderDetailsByType
