import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { useOrder } from 'backend/useOrder'
import { selectOrders } from 'features/ordersSlice'
import { status } from 'features/status'
import { LoaderTextBlock, showLoader } from 'utils/loader'
import { selectAuth } from 'features/authSlice'

// http://localhost:3000/payment/admin-payment/clnagsthl000ddkbc8pgqw11x
const AdminPayment = () => {
  const navigate = useNavigate()
  const { orderId } = useParams()
  const { getOrderById, confirmOrder } = useOrder()
  const { price } = usePrice()
  const { currentOrder } = useSelector(selectOrders)
  const { roles } = useSelector(selectAuth)

  useEffect(() => {
    if (currentOrder?.item?.id !== orderId) {
      getOrderById(orderId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  const payNow = async () => {
    showLoader(true)
    confirmOrder({
      orderId: currentOrder?.item?.id,
      metaData: {},
    })
      .then(() => {
        showLoader(false)
        navigate('/payment/done/' + currentOrder?.item?.id)
      })
      .catch((e) => console.log(e))
  }

  return (
    <div className="md:w-1/3 bg-dark-900 mx-auto p-8 flex flex-col justify-center">
      <div className="text-center">
        {currentOrder?.status === status.loading ? (
          <LoaderTextBlock />
        ) : roles.includes('adminPayment') ? (
          <div className="mx-auto">
            <h2 className="text-4xl mb-3">Admin Payment</h2>
            <p className="text-muted text-sm">Pay with Admin Payment</p>
            <p className="my-6 text-xl">
              Pay <b>{price(currentOrder?.item?.amount)}</b> with Admin Payment
            </p>
            <button
              className="mt-4 mb-8 w-full rounded-md btn-primary px-6 py-3"
              onClick={payNow}
            >
              Pay Now
            </button>
          </div>
        ) : (
          <p className="text-red-400 text-xl">
            You are not eligible for (Admin Payment)
          </p>
        )}
      </div>
      {/* <pre>{JSON.stringify(currentOrder, null, 2)}</pre> */}
    </div>
  )
}

export default AdminPayment
