import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { authActions } from 'features/authSlice'
import { useBackend } from './useBackend'

export function useAuth() {
  const dispatch = useDispatch()
  const { removeToken, updateToken, getToken, backend } = useBackend()
  const navigate = useNavigate()
  const location = useLocation()
  const loader = true

  const autoLogin = () =>
    new Promise((resolve) => {
      const token = getToken()
      if (token !== null) {
        backend()
          .get('/auth/profile')
          .then(({ adminUser, user, roles }) => {
            dispatch(
              authActions.login({
                user: user,
                token: token,
                roles: roles,
              })
            )
            if (adminUser) {
              dispatch(authActions.setAdmin({ adminUser: adminUser }))
            }
            navigate(location.pathname)
            resolve()
          })
          .catch(() => {
            if (window.location.href.search('auth/') === -1) {
              navigate('/auth/login')
            }
          })
      } else {
        if (window.location.href.search('auth/') === -1) {
          navigate('/auth/login')
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    })

  /**
   * @param {*} email
   * @param {*} password
   * @param {*} profile
   * @returns
   */
  const signup = (email, password, profile) =>
    new Promise((resolve, reject) => {
      let config = {
        loader,
        msg: { loading: 'Registering...', success: 'Registered successfully.' },
      }
      backend()
        .post('/auth/signup', { email, password, ...profile }, config)
        .then(({ user, token, roles }) => {
          updateToken(token, false)
          dispatch(
            authActions.login({
              user: user,
              token: token,
              roles: roles,
            })
          )
          return resolve(user)
        })
        .catch((error) => {
          reject(error)
        })
    })

  /**
   * @param {*} email
   * @param {*} password
   * @returns Promise<user>
   */
  const login = (email, password, remember = false) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: {
          loading: 'Authenticating...',
          success: 'Logged in successfully.',
        },
      }
      backend()
        .post('/auth/login', { email, password }, config)
        .then(({ user, roles, token }) => {
          updateToken(token, remember)
          dispatch(
            authActions.login({
              user: user,
              token: token,
              roles: roles,
            })
          )
          return resolve(user)
        })
        .catch((e) => {
          console.error(e.message)
        })
    })

  /**
   * @param {*} email
   * @param {*} password
   * @returns Promise<user>
   */
  const loginAs = (email, password, loginAs) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: {
          loading: 'Authenticating...',
          success: 'Logged in successfully.',
        },
      }
      backend()
        .post('/auth/login-as', { email, password, loginAs }, config)
        .then(({ adminUser, user, roles, token }) => {
          updateToken(token, false)
          dispatch(
            authActions.login({
              user: user,
              token: token,
              roles: roles,
            })
          )
          dispatch(authActions.setAdmin({ adminUser: adminUser }))
          return resolve(user)
        })
        .catch((e) => {
          console.error(e.message)
        })
    })

  const getUserProfile = () =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Retrieving...' },
      }
      backend()
        .get('/auth/profile', config)
        .then(({ user, token, roles }) => {
          dispatch(
            authActions.login({
              user: user,
              token: token,
              roles: roles,
            })
          )
          return resolve()
        })
        .catch((e) => {
          console.error(e.message)
        })
    })

  const logout = () =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Requesting...', success: 'Logged out successfully.' },
      }
      backend()
        .post('/auth/logout', {}, config)
        .then(() => {
          removeToken()
          dispatch(authActions.logout())
          return resolve()
        })
        .catch((e) => {
          console.error(e.message)
        })
    })

  const forgotPassword = (email) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: {
          loading: 'Requesting...',
          success: 'Password Reset Email Sent!',
        },
      }
      const url =
        window.location.protocol + '//' + window.location.host + '/auth/reset'
      backend()
        .post('/auth/forgot', { email, url }, config)
        .then(() => {
          return resolve()
        })
        .catch((e) => {
          console.error(e.message)
        })
    })

  const resetPassword = (newPassword, passwordResetCode) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Password Updated.' },
      }
      backend()
        .put('/auth/reset', { newPassword, passwordResetCode }, config)
        .then(() => {
          return resolve()
        })
        .catch((e) => {
          console.error(e.message)
        })
    })

  const updatePassword = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Password Updated.' },
      }
      backend()
        .put('/auth/password', payload, config)
        .then(() => {
          return resolve()
        })
        .catch((e) => {
          console.error(e.message)
        })
    })

  const verifyEmail = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { success: 'Email Verified.' },
      }
      backend()
        .post('/auth/verify-email', payload, config)
        .then((data) => {
          return resolve(data)
        })
        .catch((e) => {
          console.error(e.message)
        })
    })

  const updateUserProfile = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Profile Updated.' },
      }
      backend()
        .post('/auth/profile', { ...payload }, config)
        .then((data) => {
          dispatch(
            authActions.updateProfile({
              updated: payload,
            })
          )
          return resolve()
        })
        .catch((e) => {
          console.error(e.message)
        })
    })

  const loginGetToken = (tokenId) =>
    new Promise((resolve, reject) => {
      let config = {
        loader,
        msg: null,
      }
      backend()
        .get(`/auth/login-get-token/${tokenId}`, config)
        .then((data) => {
          console.log('data', data)
          updateToken(data.token, false)
          // dispatch(authActions.setToken({ token: data.token }))
          return resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })

  return {
    signup,
    verifyEmail,
    autoLogin,
    login,
    loginAs,
    logout,
    removeToken,
    getUserProfile,
    updatePassword,
    forgotPassword,
    resetPassword,
    updateUserProfile,
    loginGetToken,
  }
}
