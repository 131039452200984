import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'backend/useAuth'
import { Form, Input, Submit } from 'tui'
import { siteModule } from 'config'

const Signup = () => {
  const { signup, removeToken } = useAuth()
  const navigate = useNavigate()
  const [password, setPassword] = useState('')

  useEffect(() => {
    removeToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data) => {
    console.log('data', data)
    const profile = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      siteModule: siteModule,
    }
    signup(data.email, data.password, profile).then(() => {
      setTimeout(() => {
        // navigate('/verify-email')
        navigate('/payment/account/new')
      }, 500)
    })
  }

  return (
    <div className="md:w-1/3 bg-dark-900 mx-auto p-8 flex flex-col justify-center">
      <div className="text-center">
        <h2 className="text-4xl mb-3">Signup</h2>
        <p className="text-muted text-sm">Create your account.</p>
      </div>
      <Form onFinish={onSubmit}>
        <div className="">
          <Input.Text
            name="firstName"
            label="First Name"
            placeholder="First Name"
            rules={{ required: 'First Name is required' }}
          />
          <Input.Text
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            rules={{ required: 'Last Name is required' }}
          />
          <Input.Email
            name="email"
            label="Email"
            placeholder="Email"
            rules={{ required: 'Email is required' }}
          />
          <Input.Password
            name="password"
            label="Password"
            placeholder="Password"
            autoComplete="new-password"
            rules={{
              required: 'Password is required',
              onChange: (e) => setPassword(e.target.value),
            }}
          />
          <Input.Password
            name="password-conf"
            label="Confirm Password"
            placeholder="Confirm Password"
            autoComplete="new-password"
            rules={{
              required: 'Confirm Password is required',
              validate: (value) =>
                value === password ||
                'Password and Confirm password does not matched.',
            }}
          />
          <div className="flex justify-between items-center mt-5">
            <Submit className="btn-primary py-2">Signup</Submit>
            <span>
              Have an Account? <Link to="/auth/login">Login</Link>
            </span>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Signup
