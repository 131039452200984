import React from 'react'
import { useSelector } from 'react-redux'
import { FaTimes } from 'react-icons/fa'
import { paymentGateways } from 'ECommerce/PaymentGateways/paymentsGateways'
import { Form, Input, Submit } from 'tui'
import { useECommerce } from 'ECommerce/hooks/useECommerce'
import { selectAuth } from 'features/authSlice'
import { selectECommerce } from 'features/eCommerceSlice'
import { usePrice } from 'ECommerce/hooks/usePrice'
import { status } from 'features/status'
import { camelCaseToSentence } from 'utils/label'

const Checkout = ({ initiateOrder, items = [] }) => {
  const { roles } = useSelector(selectAuth)
  const { products, product, checkout, coupon, paymentMethod } =
    useSelector(selectECommerce)
  const { applyCoupon, clearCoupon, setPaymentMethod } = useECommerce()
  const { price } = usePrice()

  const onSubmit = (data) => {
    applyCoupon({ code: data.code, productId: product.id })
    console.log('data', data)
  }

  if (!product?.id) {
    if (products.status === status.loading) {
      return (
        <div className="text-center">
          <p className="text-2xl">Products loading...</p>
        </div>
      )
    }
    return (
      <div className="text-center">
        <p className="text-red-300 text-2xl">No product selected</p>
      </div>
    )
  }

  return (
    <div>
      <div className="grid lg:grid-cols-2">
        <div className="px-4">
          <p className="text-xl font-medium">Payment Methods</p>
          <div className="mt-5 grid gap-6">
            {paymentGateways
              .filter(
                (gateway) =>
                  // checkout.total === 0 ||
                  // coupon.applied === gateway.free ||
                  gateway.free === (checkout.total === 0)
              )
              .map(
                (gateway) =>
                  roles.includes(gateway.role) && (
                    <div className="relative" key={gateway.id}>
                      <input
                        className="peer hidden"
                        id={gateway.id}
                        type="radio"
                        name="radio"
                        onClick={() => setPaymentMethod(gateway.id)}
                        defaultChecked={paymentMethod === gateway.id}
                        // disabled={
                        //   coupon.applied &&
                        //   paymentMethod === 'FreeOrder' &&
                        //   gateway.id !== 'FreeOrder'
                        // }
                        // readOnly
                      />
                      <span className="peer-checked:border-gold-500 peer-disabled:border-disabled peer-disabled:cursor-not-allowed absolute right-4 top-1/2 box-content block h-2 w-2 -translate-y-1/2 rounded-full border-8 border-muted"></span>
                      <label
                        className="peer-checked:border-2 peer-checked:border-gold-500 peer-disabled:border-disabled peer-disabled:cursor-not-allowed flex cursor-pointer select-none rounded-lg border border-muted p-4"
                        htmlFor={gateway.id}
                      >
                        <img
                          className="w-12 object-contain rounded-lg"
                          src={gateway.logo}
                          alt=""
                        />
                        <div className="ml-5">
                          <span className="mt-2 font-semibold text-lg">
                            {gateway.label}
                          </span>
                          <p className="text-muted text-sm leading-6">
                            {gateway.description}
                          </p>
                        </div>
                      </label>
                    </div>
                  )
              )}
          </div>
        </div>
        <div className="mt-10 px-4 lg:mt-0">
          <p className="text-xl font-medium">Payment Details</p>
          <div className="py-2">
            <Form
              onFinish={onSubmit}
              initialValues={{
                code: coupon.code,
              }}
            >
              <label className="mt-2">Coupon Code</label>
              <div className="flex justify-between space-x-4">
                <div className="w-3/4">
                  <Input.Text
                    // label="Coupon Code"
                    type="text"
                    id="code"
                    name="code"
                    className="tw-control"
                    placeholder="Code"
                    rules={{ required: 'Code is required' }}
                    // icon={
                    //   coupon.applied ? (
                    //     <FaTimes
                    //       title="Remove Coupon"
                    //       onClick={() => clearCoupon()}
                    //     />
                    //   ) : null
                    // }
                  />
                </div>
                <div className="w-1/4 mb-1">
                  <Submit className="bg-primary p-2 px-4 w-full">
                    <span>
                      Apply
                      {/* {coupon.applied ? (
                        <>
                          Applied <FaCheck className="inline-flex" />
                        </>
                      ) : (
                        'Apply'
                      )} */}
                    </span>
                  </Submit>
                </div>
              </div>
            </Form>
            <div className="mt-6">
              <div className="flex border-t py-3 items-start justify-between">
                <p className="text-sm font-medium">
                  <span>{product.name}</span>
                  {items.map((item, i) => (
                    <span className="text-muted block" key={i}>
                      {item.name}
                    </span>
                  ))}
                </p>
                <p className="font-semibold">{price(product.payableAmount)}</p>
              </div>
              <div className="flex border-t py-3 items-center justify-between">
                <p className="text-sm font-medium">Subtotal</p>
                <p className="font-semibold">{price(checkout.subTotal)}</p>
              </div>
              {/* <pre>{JSON.stringify(checkout, null, 2)} </pre> */}
              {coupon.applied && (
                <div className="flex border-t py-3 items-end justify-between">
                  <div>
                    <p className="text-sm font-medium">Discount</p>
                    <div className="text-sm text-muted">
                      <span className="text-muted pr-2 text-xs">
                        {coupon.code}
                      </span>
                      <FaTimes
                        className="inline-flex text-red-500 mr-2 cursor-pointer"
                        title="Remove Coupon"
                        onClick={() => clearCoupon()}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <span className="text-muted pr-2 text-xs">
                      {coupon.title}
                    </span>
                    <span className="font-semibold">
                      {price(-coupon.discountAmount)}
                    </span>
                  </div>
                </div>
              )}
              {/* <div className="flex items-center justify-between">
                <p className="text-sm font-medium">Shipping</p>
                <p className="font-semibold">$8.00</p>
              </div> */}
            </div>
            <div className="flex border-y py-3 items-center justify-between">
              <p className="text-sm font-medium">Total</p>
              <p className="text-2xl font-semibold">{price(checkout.total)}</p>
            </div>
          </div>
          {paymentMethod ? (
            <button
              className="mt-4 mb-8 w-full rounded-md btn-primary px-6 py-3"
              onClick={initiateOrder}
            >
              <>Proceed with {camelCaseToSentence(paymentMethod)}</>
              {/*
              {checkout.total > 0 ? (
                <>Proceed with {camelCaseToSentence(paymentMethod)}</>
              ) : (
                <>Place Order Free</>
              )} */}
            </button>
          ) : (
            <button
              className="mt-4 mb-8 w-full rounded-md btn-primary px-6 py-3"
              disabled
            >
              Select Payment Method
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Checkout
