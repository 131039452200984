import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { FaDownload, FaFileInvoiceDollar } from 'react-icons/fa'
import Placeholder from 'Components/Placeholder'
import { useCertificate } from 'backend/useCertificate'
import { LoaderBlock } from 'utils/loader'
import { useBusiness } from 'backend/useBusiness'
import { businessTypes, paymentStatus } from 'dataSource/constants'
import { getChineseSign, getWesternSign } from 'utils/zodiac'

const Certificates = () => {
  const { id } = useParams()
  const [formData, setFormData] = useState({})

  const { certificates } = useCertificate()
  const { getBusiness } = useBusiness()

  useEffect(() => {
    getBusiness(id).then((item) => {
      let beneficiaries = item.beneficiaries
      if (item.businessType === businessTypes.legacy) {
        beneficiaries = beneficiaries.map((b, i) => {
          const d = dayjs(b.dob)
          const year = d.format('YYYY')
          const month = d.format('MM')
          const day = d.format('DD')
          const westernSign = getWesternSign(day, month)
          const chineseSign = getChineseSign(year)
          return { ...b, westernSign, chineseSign }
        })
      }
      setFormData({ ...item, beneficiaries })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // zodiacSystem

  return (
    <div>
      <div>
        <h2 className="text-2xl mb-5">Download Certificates</h2>
      </div>
      <div className="bg-black p-4 rounded-lg">
        {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
        {formData?.status !== paymentStatus.Confirmed ? (
          <div className="text-center">
            <Placeholder
              icon={FaFileInvoiceDollar}
              text="Please complete the payment to download certificates"
            />
          </div>
        ) : certificates?.status === 'loading' ? (
          <LoaderBlock />
        ) : certificates?.items?.length === 0 ? (
          <Placeholder
            icon={FaDownload}
            text="Your certificates will display here"
          />
        ) : (
          <div>
            {certificates.items
              .filter(
                (item) =>
                  item.category.includes(formData.shareholderType) &&
                  item.certificateType === formData.businessType &&
                  item.entityEnding === 'ALL'
              )
              .map((item, i) => (
                <div key={i}>
                  {item.shareholderCount === 0 ? (
                    item?.isZodiac === true ? (
                      <>
                        {formData.beneficiaries.map(
                          (b, i) =>
                            item.zodiacSystem === 'Western' &&
                            item.zodiacSign === b.westernSign && (
                              <DownloadBirthCertificateLink
                                businessId={formData.id}
                                item={item}
                                beneficiary={b}
                                i={i + 1}
                              />
                            )
                        )}
                        {formData.beneficiaries.map(
                          (b, i) =>
                            item.zodiacSystem === 'Chinese' &&
                            item.zodiacSign === b.chineseSign && (
                              <DownloadBirthCertificateLink
                                businessId={formData.id}
                                item={item}
                                beneficiary={b}
                                i={i + 1}
                              />
                            )
                        )}
                      </>
                    ) : (
                      <DownloadLink businessId={formData.id} item={item} />
                    )
                  ) : (
                    formData.shareholderCount === item.shareholderCount && (
                      <DownloadLink businessId={formData.id} item={item} />
                    )
                  )}
                </div>
              ))}
            {certificates.items
              .filter(
                (item) =>
                  item.category.includes(formData.shareholderType) &&
                  item.certificateType === formData.businessType &&
                  item.entityEnding === formData.entityEnding
              )
              .map((item, i) => (
                <div key={i}>
                  {item.shareholderCount === 0 ? (
                    <DownloadLink businessId={formData.id} item={item} />
                  ) : (
                    formData.shareholderCount === item.shareholderCount && (
                      <DownloadLink businessId={formData.id} item={item} />
                    )
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Certificates

const DownloadLink = ({ item, businessId }) => {
  const { getCertificateUrl } = useCertificate()
  return item?.status === 'Active' ? (
    <a
      href={getCertificateUrl(businessId, item.id)}
      className="secondary w-full my-1 block"
      target="_blank"
      rel="noreferrer"
    >
      {item.title}
    </a>
  ) : (
    <span>{item.title}</span>
  )
}

const DownloadBirthCertificateLink = ({ item, businessId, beneficiary, i }) => {
  const { getCertificateUrl } = useCertificate()
  return item?.status === 'Active' ? (
    <a
      href={getCertificateUrl(businessId, item.id, { b: i })}
      className="secondary w-full my-1 block"
      target="_blank"
      rel="noreferrer"
    >
      {item.title} FOR BENEFICIARY {i}{' '}
      {String(beneficiary.firstName).toUpperCase()}
    </a>
  ) : (
    <span>{item.title}</span>
  )
}
