import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'

import Placeholder from 'Components/Placeholder'
import { Form, Input, Submit } from 'tui'
import { LoaderTextBlock } from 'utils/loader'
import { useBusiness } from 'backend/useBusiness'
import { useOrder } from 'backend/useOrder'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { paymentStatus } from 'dataSource/constants'

const defaultData = {
  items: [],
  status: 'idle',
}
const CompanySearchForGoodStanding = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [filtered, setFiltered] = useState(false)
  const [business, setBusiness] = useState(defaultData)
  const { searchBusiness } = useBusiness()
  const { storeGoodStanding } = useOrder()
  const [selectedBusiness, setSelectedBusiness] = useState(0)

  const onSubmit = (data) => {
    searchBusiness({ ...data }).then((result) => {
      setBusiness({
        items: result.items,
        status: 'idle',
      })
      setFiltered(true)
    })
    console.log('data', data)
  }

  const onProceed = (data) => {
    storeGoodStanding(data).then((item) => {
      console.log('result', item)
      navigate(`/payment/good-standing/${item.id}`)
    })
  }

  const handleClick = (index) => {
    setSelectedBusiness(index)
    console.log('index', index)
  }

  return (
    <div>
      <h1 className="text-3xl mb-4">Certificate of Good Standing</h1>
      <div className="mb-5">
        {/* <div className="text-muted">
          The Certificate of Good Standing is provided by the Delaware Secretary of State declaring a company is current and up-to-date on all annual Delaware compliance fees. Harvard Business Services, Inc. can obtain a Certificate of Good Standing for you in two business days or less. Simply fill out the form below.
        </div>
        <div className="text-muted">
          This database determines if a business name is available for use in forming a new Blockchain Trust company.
        </div>
        <div className="text-muted">
          Our online technology allows us to quickly and seamlessly determine the availability of a name for use in forming a new Blockchain Trust entity.
        </div> */}
        <div className="text-muted">Request Certificate of Good Standing.</div>
      </div>
      <div className="mt-2 text-center">
        <h3 className="py-2 text-2xl">Provide Company Name</h3>
        <p className="text-muted">
          To locate the exact company, enter the company name in the field
          bellow and click Search.
        </p>
        <div className="flex justify-center my-5">
          <Form onFinish={onSubmit}>
            <div className="flex justify-between">
              <div className="mr-2 w-4/5">
                <Input.Text
                  name="businessFullName"
                  placeholder="Search by Company Name"
                  className=""
                  defaultValue={searchParams?.get('c')}
                />
              </div>
              <div className="flex items-center w-1/5">
                <Submit className="bg-primary flex items-center p-2 px-4 mr-2">
                  <FaSearch className="mr-2" />
                  <span>Search</span>
                </Submit>
              </div>
            </div>
          </Form>
        </div>
        <div className="text-left">
          {business.status === 'loading' ? (
            <LoaderTextBlock />
          ) : business?.items?.length === 0 ? (
            <Placeholder
              icon={FaSearch}
              text={
                filtered
                  ? 'No result found'
                  : 'Type company name and click on search to get result.'
              }
            />
          ) : (
            business?.items?.map((item, i) => (
              <div className="bg-black my-4 rounded-md p-4">
                <div
                  className="flex justify-between cursor-pointer"
                  onClick={() => handleClick(item.id)}
                >
                  <p className="text-gold-400 text-lg">
                    {item.businessFullName}
                  </p>
                  <p className="mt-2 text-xs text-gold-400">
                    {item.filingNumberFormatted}
                  </p>
                </div>
                {selectedBusiness === item.id && (
                  <div className="mt-5">
                    {item.goodStanding === 'Requested' ? (
                      <div className="text-center">
                        This Business has already requested to get Certificate
                        of Good Standing.
                      </div>
                    ) : item.status !== paymentStatus.Confirmed ? (
                      <div className="text-center">
                        This Business is not eligible to get Certificate of Good
                        Standing.
                      </div>
                    ) : (
                      <Form onFinish={onProceed}>
                        <div className="">
                          <div className="w-full mt-3">
                            <Input.Email
                              name="deliveryEmail"
                              required
                              label="What email would you like the Certificate of Good Standing sent to?"
                              placeholder="Email"
                              // defaultValue={item.email}
                              rules={{ required: 'Delivery Email is required' }}
                            />
                          </div>
                        </div>
                        <div className="">
                          <div className="w-full mt-3">
                            <Input.Text
                              name="whereToUse"
                              required
                              label="Where will the Certificate of Good Standing be used?"
                              // placeholder="Where will the Certificate of Good Standing be used?"
                              rules={{ required: 'This field is required' }}
                            />
                            <Input.Hidden
                              name="businessId"
                              defaultValue={item.id}
                            />
                            <Input.Hidden
                              name="title"
                              defaultValue={item.businessFullName}
                            />
                          </div>
                        </div>
                        <div className="flex justify-end mt-5">
                          <Submit className="btn-primary py-2 px-4">
                            Proceed
                          </Submit>
                        </div>
                      </Form>
                    )}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default CompanySearchForGoodStanding
