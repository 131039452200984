import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  FaBuilding,
  FaBitbucket,
  FaFileInvoiceDollar,
  FaHandPointRight,
  // FaSignOutAlt,
  FaEdit,
  FaDollarSign,
  FaDownload,
} from 'react-icons/fa'

import Badge from 'tui/Components/Badge'
import Placeholder from 'Components/Placeholder'
import { LoaderTextBlock } from 'utils/loader'
import { useOrder } from 'backend/useOrder'
import { usePrice } from 'ECommerce/hooks/usePrice'
// import { useAuth } from 'backend/useAuth'
import { useBusiness } from 'backend/useBusiness'
import {
  paymentButtonVisibility,
  paymentStatus,
  siteModules,
} from 'dataSource/constants'
import hourGlass from 'assets/hourglass.png'
import { selectAuth } from 'features/authSlice'
import { getLinkByBusinessType } from 'utils/logic'
import { siteModule } from 'config'
import { selectCms } from 'features/cmsSlice'
import { selectBusiness } from 'features/businessSlice'
import { selectOrders } from 'features/ordersSlice'
import { selectUserAssets } from 'features/assetSlice'
import { status } from 'features/status'
import { useAsset } from 'backend/useAsset'
const dayjs = require('dayjs')

// const paymentButtonVisibility = ['Pending', 'Expired', 'Failed']

const Dashboard = () => {
  const { services } = useSelector(selectCms)
  const { business } = useSelector(selectBusiness)
  const { orders, upcomingBills } = useSelector(selectOrders)
  const userAssets = useSelector(selectUserAssets)
  const { userAsset } = useAsset()
  // const { logout } = useAuth()
  const auth = useSelector(selectAuth)

  const { myBusiness } = useBusiness()
  const { myOrders, myUpcomingBills } = useOrder()
  const { price } = usePrice()

  useEffect(() => {
    if (auth.status === status.fulfilled && auth.logged === true) {
      myBusiness({ limit: 10 })
      myOrders({ limit: 5 })
      myUpcomingBills({ limit: 5 })
      userAsset({ limit: 10 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const handleLogout = () => {
  //   logout().then(() => {
  //     window.location.href = '/auth/login'
  //   })
  // }

  return (
    <div className="flex flex-col justify-center md:mb-24">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl">Account Dashboard</h1>
        {/* <button
          className="text-primary pr-0"
          title="Sign out"
          onClick={handleLogout}
        >
          <FaSignOutAlt className="text-3xl" />
        </button> */}
      </div>
      <div className="grid md:grid-cols-2 gap-8 mt-5">
        <div className="bg-black rounded-md overflow-hidden">
          <div className="flex justify-between items-center bg-gold-600 p-5">
            <div className="font-bold flex items-center">
              <FaBuilding className="mr-2" />
              <span>Your Companies</span>
            </div>
            <Link
              to="companies"
              className="underline font-bold text-sm text-white hover:text-gray-200"
            >
              View All Companies
            </Link>
          </div>
          <div className="p-5">
            {business.status === status.loading ? (
              <LoaderTextBlock />
            ) : business?.items?.length === 0 ? (
              <Placeholder
                icon={FaBuilding}
                text="Your companies will display here"
              />
            ) : (
              business?.items?.map((item, i) => (
                <div
                  className="flex justify-between items-center uppercase mb-5 text-sm font-semibold"
                  key={i}
                >
                  <Link to={`/company/${item.id}`} state={item}>
                    {item.businessFullName}
                  </Link>
                  <div className="flex justify-between">
                    {item.status === paymentStatus.Confirmed && (
                      <Link
                        to={`/company/${item.id}/certificates`}
                        className="p-1"
                      >
                        <FaDownload />
                      </Link>
                    )}
                    {!item.agreed && (
                      <Link
                        to={getLinkByBusinessType(item, 'edit')}
                        className="p-1"
                      >
                        <FaEdit />
                      </Link>
                    )}
                    {item.agreed &&
                      paymentButtonVisibility.includes(item.status) && (
                        <Link
                          to={getLinkByBusinessType(item, 'payment')}
                          className="p-1"
                          title="Make Payment"
                        >
                          <FaDollarSign />
                        </Link>
                      )}
                    {item.status === paymentStatus.Awaiting && (
                      <span className="p-1 pr-0">
                        <img
                          src={hourGlass}
                          alt="Awaiting For Payment"
                          title="Awaiting For Payment"
                          style={{
                            width: '1.5rem',
                          }}
                        />
                      </span>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="bg-black rounded-md overflow-hidden">
          <div className="flex justify-between items-center bg-gold-600 p-5">
            <div className="font-bold flex items-center">
              <FaFileInvoiceDollar className="mr-2" />
              <span>Upcoming Bills</span>
            </div>
            <Link
              to="upcoming"
              className="underline font-bold text-sm text-white hover:text-gray-200"
            >
              View All Bills
            </Link>
          </div>
          <div className="p-5">
            {upcomingBills.status === status.loading ? (
              <LoaderTextBlock />
            ) : upcomingBills?.items?.length === 0 ? (
              <Placeholder
                icon={FaFileInvoiceDollar}
                text="Upcoming Bills will display here"
              />
            ) : (
              upcomingBills?.items?.map((item, i) => (
                <div className="py-2" key={i}>
                  <div className="flex justify-between">
                    <span className="text-lg">
                      {item.fees || 'Annual Renewal Fees'}
                    </span>
                    <span className="text-muted text-sm">
                      {dayjs(item.createdAt).format('MMM DD, YYYY')}
                    </span>
                  </div>
                  <div className="text-md uppercase flex justify-between">
                    <span className="underline text-sm text-gold-400">
                      {item?.title}
                    </span>
                    {/* <span className="border-2 rounded-md px-2">
                        {item.dueDate}
                      </span> */}
                    <Badge status={item.status}>{item.status}</Badge>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="bg-black rounded-md overflow-hidden">
          <div className="flex justify-between items-center bg-gold-600 p-5">
            <div className="font-bold flex items-center">
              <FaBitbucket className="mr-2" />
              <span>Recent Payments</span>
            </div>
            <Link
              to="orders"
              className="underline font-bold text-sm text-white hover:text-gray-200"
            >
              View All Payments
            </Link>
          </div>
          <div className="p-5">
            {orders.status === status.loading ? (
              <LoaderTextBlock />
            ) : orders?.items?.length === 0 ? (
              <Placeholder
                icon={FaBitbucket}
                text="Payments will display here"
              />
            ) : (
              orders?.items?.map((item, i) => (
                <div className="pb-3" key={i}>
                  {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
                  <div className="flex items-center justify-between mx-2 mb-2 text-sm mt-4 font-semibold">
                    <Link to={`/orders/${item.id}`} state={item}>
                      Order {item.id}
                    </Link>
                    <div>
                      {/* <span className="text-white">{dayjs(item.createdAt).format('MMM DD, YYYY')}</span> */}
                      <span className="text-xs text-muted">
                        {price(item.amount, item.currency)} — {item.status}
                      </span>
                    </div>
                  </div>
                  <div className="px-2 text-muted">{item.title}</div>
                  {/* <Badge status={item.status}>{item.status}</Badge> */}
                </div>
              ))
            )}
          </div>
        </div>
        <div className="bg-black rounded-md overflow-hidden">
          <div className="flex justify-between items-center bg-gold-600 p-5">
            <div className="font-bold flex items-center">
              <FaHandPointRight className="mr-2" />
              <span>Select Service</span>
            </div>
            <Link
              to="services"
              className="underline font-bold text-sm text-white hover:text-gray-200"
            >
              View All Services
            </Link>
          </div>
          <div className="p-5">
            {services?.items?.length === 0 ? (
              <Placeholder
                icon={FaHandPointRight}
                text="Service will display here"
              />
            ) : (
              <>
                {siteModule === siteModules.Reseller &&
                  auth?.user?.agentStatus !== paymentStatus.Confirmed && (
                    <div className="pb-1">
                      <div className="uppercase flex text-gold-400 justify-between mx-2 mb-2 text-sm mt-4 font-semibold">
                        <Link to="/payment/account/new">Make Agent Fees</Link>
                      </div>
                    </div>
                  )}
                {services?.items
                  ?.filter((item) => item.siteModules.includes(siteModule))
                  .map((item, i) => (
                    <div className="pb-1" key={i}>
                      <div className="uppercase flex text-gold-400 justify-between mx-2 mb-2 text-sm mt-4 font-semibold">
                        <Link to={item.to}>{item.name}</Link>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
        <div className="bg-black rounded-md overflow-hidden">
          <div className="flex justify-between items-center bg-gold-600 p-5">
            <div className="font-bold flex items-center">
              <FaBuilding className="mr-2" />
              <span>Your Assets</span>
            </div>
            <Link
              to="assets"
              className="underline font-bold text-sm text-white hover:text-gray-200"
            >
              View all assets
            </Link>
          </div>
          <div className="p-5">
            {userAssets.status === status.loading ? (
              <LoaderTextBlock />
            ) : !userAssets?.items?.length ? (
              <Placeholder
                icon={FaBuilding}
                text="Your Assets will display here"
              />
            ) : (
              userAssets?.items.map((item, i) => (
                <div
                  className="flex justify-between items-center uppercase mb-5 text-sm font-semibold"
                  key={i}
                >
                  <Link to={`/assets/${item.id}`} state={item}>
                    {item?.name}
                  </Link>
                  <div className="flex justify-between">
                    {item.status === paymentStatus.Confirmed && (
                      <Link
                        to={`/company/${item.id}/certificates`}
                        className="p-1"
                      >
                        <FaDownload />
                      </Link>
                    )}
                    {item.agreed &&
                      paymentButtonVisibility.includes(item.status) && (
                        <Link
                          to={getLinkByBusinessType(item, 'payment')}
                          className="p-1"
                          title="Make Payment"
                        >
                          <FaDollarSign />
                        </Link>
                      )}
                    {item.status === paymentStatus.Awaiting && (
                      <span className="p-1 pr-0">
                        <img
                          src={hourGlass}
                          alt="Awaiting For Payment"
                          title="Awaiting For Payment"
                          style={{
                            width: '1.5rem',
                          }}
                        />
                      </span>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
